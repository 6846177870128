export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    children: [
      {
        title: "Quote Price",
        route: "quote-price",
        icon: "TagIcon",
        resource: "QoutePrice",
        action: "manage",
      },
    ],
  },
  {
    title: "Order",
    route: "order",
    icon: "BoxIcon",
    children: [
      {
        title: "Outlet",
        route: "Outlet-tracker",
        icon: "BriefcaseIcon",
        resource: "Outlet",
        action: "manage",
      },
      {
        title: "B2B",
        route: "B2B-tracker",
        icon: "BoldIcon",
        resource: "B2B",
        action: "manage",
      },
      {
        title: "Singapore",
        route: "singapore-tracker",
        icon: "FlagIcon",
        resource: "Singapore",
        action: "manage",
      },
      {
        title: "Distributor",
        route: "distributor-tracker",
        icon: "ShoppingBagIcon",
      },
      {
        title: "Ondemand",
        route: "ondemand-tracker",
        icon: "BarChartIcon",
      },
      {
        title: "DHL",
        route: "DHL-tracker",
        icon: "TruckIcon",
        resource: "DHL",
        action: "manage",
      },
      {
        title: "In Warehouse",
        route: "in-warehouse",
        icon: "InboxIcon",
        resource: "InWarehouse",
        action: "manage",
      },
      {
        title: "FAMA",
        route: "order",
        icon: "LayersIcon",
        children: [
          {
            title: "Agrofun",
            route: "agrofun-tracker",
            icon: "FeatherIcon",
            resource: "Agrofun",
            action: "manage",
          },
          {
            title: "Agrobazaar",
            route: "agrobazaar-tracker",
            icon: "GiftIcon",
            resource: "Agrobazaar",
            action: "manage",
          },
        ],
      },
    ],
  },
  {
    title: "Create Delivery",
    route: "create-delivery",
    icon: "PackageIcon",
    resource: "CreateDelivery",
    action: "manage",
  },
  {
    title: "Route Planning",
    icon: "CompassIcon",
    children: [
      {
        title: "Create Route",
        route: "map_routing",
        icon: "CheckCircleIcon",
        resource: "CreateRoute",
        action: "manage",
      },
      {
        title: "Route List",
        route: "route-tracker",
        icon: "CornerRightDownIcon",
        resource: "RouteTracker",
        action: "manage",
      },
    ],
  },
  {
    title: "Track & Trace",
    route: "track-and-trace",
    icon: "MapIcon",
    resource: "TrackAndTrace",
    action: "manage",
  },
  {
    title: "Postal Coverage",
    route: "postal-coverage",
    icon: "MapPinIcon",
    children: [
      {
        title: "Core List",
        route: "core-list",
        icon: "ListIcon",
        resource: "CoreList",
        action: "manage",
      },
      {
        title: "Outlet",
        route: "Outlet-postal",
        icon: "BriefcaseIcon",
        resource: "Outlet",
        action: "manage",
      },
    ],
  },
  // {
  //   title: "Price Checker",
  //   route: "price-checker",
  //   icon: "DollarSignIcon",
  //   resource: "PriceChecker",
  //   action: "manage",
  // },
  {
    title: "Price Checker",
    route: "price-checker",
    icon: "DollarSignIcon",
    resource: "PriceChecker",
    action: "manage",
    children: [
      // {
      //   title: "Distributor",
      //   route: "distributor-price",
      //   icon: "ShoppingBagIcon",
      //   resource: "DistributorPrice",
      //   action: "manage",
      // },
      // {
      //   title: "Outlet",
      //   route: "outlet-price",
      //   icon: "BriefcaseIcon",
      //   resource: "OutletPrice",
      //   action: "manage",
      // },
      {
        title: "Price List",
        route: "price-list",
        icon: "FeatherIcon",
        resource: "PriceList",
        action: "manage",
      },
      {
        title: "Special Rate",
        route: "special-rate",
        icon: "KeyIcon",
        resource: "SpecialRate",
        action: "manage",
      },
    ],
  },
  {
    title: "Report",
    route: "report",
    icon: "ClipboardIcon",
    children: [
      {
        title: "Distributor",
        route: "distributor",
        icon: "ShoppingBagIcon",
        resource: "DistributorReport",
        action: "manage",
      },
      {
        title: "Master List",
        route: "master-list",
        icon: "ListIcon",
        resource: "MasterList",
        action: "manage",
      },
      {
        title: "Outlet",
        route: "Outlet-report",
        icon: "BriefcaseIcon",
        resource: "OutletPostal",
        action: "manage",
      },
      {
        title: "DHL",
        route: "dhl-report",
        icon: "TruckIcon",
        resource: "DHLPostal",
        action: "manage",
      },
      {
        title: "FAMA",
        route: "report",
        icon: "LayersIcon",
        children: [
          {
            title: "Agrofun",
            route: "agrofun-report",
            icon: "FeatherIcon",
            resource: "Agrofun",
            action: "manage",
          },
          {
            title: "Agrobazaar",
            route: "agrobazaar-report",
            icon: "GiftIcon",
            resource: "Agrobazaar",
            action: "manage",
          },
          {
            title: "All",
            route: "all-report",
            icon: "ColumnIcon",
            resource: "All",
            action: "manage",
          },
        ],
      },
    ],
  },
  {
    title: "Admin Access",
    route: "admin-access",
    icon: "UserPlusIcon",
    children: [
      {
        title: "Users",
        route: "Outlet",
        icon: "UserIcon",
        resource: "User",
        action: "manage",
      },
      {
        title: "Authority",
        route: "authority",
        icon: "LockIcon",
        resource: "Authority",
        action: "manage",
      },
    ],
  },
  {
    title: "Drivers",
    route: "phone-book",
    icon: "TruckIcon",
    resource: "Drivers",
    action: "manage",
  },
  {
    title: "Analytics",
    route: "analytics",
    icon: "BarChartIcon",
    children: [
      {
        title: "Shipment",
        route: "shipment-analytics",
        icon: "BarChart2Icon",
        resource: "ShipmentAnalytics",
        action: "manage",
      },
      {
        title: "Order",
        route: "order-analytics",
        icon: "BarChart2Icon",
        resource: "OrderAnalytics",
        action: "manage",
      },
      {
        title: "Delivery Status",
        route: "delivery-status-analytics",
        icon: "BarChart2Icon",
        resource: "DeliveryStatusAnalytics",
        action: "manage",
      },
      {
        title: "User",
        route: "user-analytics",
        icon: "BarChart2Icon",
        resource: "UserAnalytics",
        action: "manage",
      },
      {
        title: "Location",
        route: "location-analytics",
        icon: "BarChart2Icon",
        resource: "LocationAnalytics",
        action: "manage",
      },
    ],
  },
  {
    title: "OTP Logs",
    route: "otp-logs",
    icon: "UsersIcon",
    resource: "OTP Logs",
    action: "manage",
  },
  {
    title: "Assign Voucher",
    route: "voucher",
    icon: "ToolIcon",
    resource: "Assign Voucher",
    action: "manage",
  },
  // {
  //   title: "Inventory",
  //   route: "inventory",
  //   icon: "ArchiveIcon",
  //   resource: "Inventory",
  //   action: "manage",
  // },
];
